import BasePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import Form from './form';
import detailForm from './detail';

export default {
  name: 'price_maintenance',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    detailForm,
  },
  created() {
    this.getConfigList('tpm_price_maintenance_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
      if (val.code === 'check_detail') {
        this.formName = 'detailForm';
        this.modalConfig.title = '查看明细';
        this.propsObjInData.row = row;
        this.openFull();
      }
      if (val.code === 'lose_efficacy') {
        this.$confirm('是否确认当前品鉴酒价格失效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request.post('/tpm/tpmTastingPrice/invalid', { id: row.id }).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '操作成功',
                });
                this.getList();
              }
            });
          });
      }
    },
  },
};
