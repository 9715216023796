import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import ProductList from './product_list.vue';

formCreate.component('ProductList', ProductList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_price_maintenance_form',
    };
  },
  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'productNameGift') { // 选择本品酒
        v.props = {
          ...v.props,
          params: {
            functionCode: 'product_list',
            paramData: {
              enableStatus: '009',
              isShelf: 'Y',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'price') { // 价格
        v.props = {
          ...v.props,
          min: 0,
          precision: 2,
          controls: false,
        };
      }
      if (item.field === 'effectiveTime') { // 生效时间
        item.props = {
          ...item.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '00:00:00',
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const productNameGift = this.getRule('productNameGift');
      // 本品酒
      productNameGift.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productNameGift: e[0].productName,
            productCodeGift: e[0].productCode,
          });
        } else {
          this.setValue({
            productNameGift: null,
            productCodeGift: null,
          });
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .post('/tpm/tpmTastingPolicy/getTastingPolicyDetail', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const { result } = res;
              this.setValue(result);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmTastingPrice/add';
        const params = formData;
        delete params.title1;
        if (this.formConfig.code === 'edit') {
          params.id = this.formConfig.row.id;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
