<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { id: this.propsObj.row.id };
    this.getConfigList('tpm_price_maintenance_detail');
  },
  methods: {

  },
};
</script>
